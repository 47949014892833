import React from "react";
import "../css/section.css";

export default function Section<T extends React.ReactNode>({ className, title, children }: { className: string, title: string, children: T }) {
    return (
        <div className="genericContainer">
            <h1 className="sectionTitle">{title}</h1>
            <div className={className}>
                {children}
            </div>
        </div>
    )
}