import React, { useState, useEffect } from "react";
import "../css/homepage.css";

const HomePage = () => {

  return (
    <main>
        <div>
          <div className="headerContainer">
            <div className="outerAbout">
              <img src="images/me.jpg" alt="A picture of Ray" className="ray" />
              <div className="aboutBar">
                <h1 className="helloTitle">Hi, I'm Ray Valenzuela</h1>
                <h3 className="myDescription">
                  Computer Engineer - Full Stack Developer - Cal Poly
                </h3>
                <p className="welcome">Welcome To My Website</p>
                <p className="introPara">
                  Here, you'll find information about my professional and
                  personal life
                </p>
              </div>
            </div>
          </div>
        </div>
    </main>
  );
};

export default HomePage;
