import React from "react"
import "../css/projectcard.css";
import Card from "../classes/Card";

export default function CardComponent({card} : {card : Card}) {

    return (
        <div className="projectCardContainer">
            <div className="imageContainer">
                <img className="image" src={card.imagePath} alt="image" />
            </div>
            <div className="titleLinkContainer">
                <h2 className="title">{card.title}</h2>
                {card.isProject && <a href={card.link} target="_blank"><img className="githubLogo" src="icons/githubLogo.jpg" alt="github-logo" /></a>}
            </div>
            <p className="summary">{card.summary}</p>
        </div>
    )
}