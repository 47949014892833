import React from "react";
import Card from "./Card.ts"
import CardComponent from "../components/card.tsx";

export default class Data {

    static getHobbyComponents() {
        const hobbyCards = [
            new Card("images/friends.jpeg", "Quality Friend Time", "Nothing is better than a good, deep, soul healing, laugh with friends. I love to go on hikes, play poker, have beach days, and have jam sessions with my friends because it's my favorite form of relaxation.", "", false),
            new Card("images/guitar.png", "Guitar", "I have and love to play the electric guitar. Regardless of if I'm learning, writing, playing, or improvising to a song, I always find myself getting lost in the flow of the music.", "", false),
            new Card("images/bouldering.jpg", "Bouldering", "Bouldering is the most enjoyable form of intense exercise to me. I love the rush of finally finishing a climb that took multiple tries and the blisters I feel afterwards.", "", false),
            new Card("images/painting.jpg", "Painting", "Whenever I find myself motivated to create, painting is my go to because it allows me to expose my emotions and creativity in a calming and relaxing way.", "", false),
            new Card("images/subnautica.jpg", "Gaming", "When spring comes around and rain begins to pour, there's no better time to get lost in the fantastic and creative reality that story video games provide. Video Games are the most beautiful form of storytelling to exist.", "", false),
        ]
        let resultComponents: React.JSX.Element[] = []

        hobbyCards.forEach((card) => {
            resultComponents.push(
                <CardComponent card={card} />
            )
        })
        return resultComponents;
    }

    static getPassionComponents() {
        const passionCards = [
            new Card("images/infrastructure.jpg", "Cloud Architecture and Infrastructure", "The reason a lot of my personal projects revolve around building full stack applications is because I thoroughly enjoy going through the entire process of architecting and implementing cloud applications.", "", false),
            new Card("images/smile.png", "Nihilistic Optimism", "The purpose of our existence on this rock floating through the universe is to figure out how to enjoy the time we spend doing that and then do that. ", "", false),
            new Card("images/creativity.png", "Creativity", "Creativity is rooted at the base of everything that has ever been made by humans. I try to apply creativity to everything I do, which in turn makes a lot of my hobbies, creative.", "", false),
        ]
        let resultComponents: React.JSX.Element[] = []

        passionCards.forEach((card) => {
            resultComponents.push(
                <CardComponent card={card} />
            )
        })
        return resultComponents;
    }

    static getPersonalProjectsComponents() {
        const personalProjectCards = [
            new Card("images/virtualhome.png", "Virtual Home", "Developed a full-stack application using Firebase and Next.js to address home related organization needs. The app effectively manages tasks, tracks inventory, and provides reminders for various activities. A robust type hierarchy was implemented to categorize and represent physical objects within the users virtual homes, including homes, spaces, maintainables, tasks, and notes.", "https://github.com/AceOfRay/VirtualHome.git", true),
            new Card("images/bootloader.png", "Custom BootLoader", "Implemented a customizable bootloader application that streamlines the setup process for various computing tasks. By offering a choice of pre-defined modes, the bootloader automatically launches essential applications and websites tailored to each specific workflow.", "https://github.com/AceOfRay/CustomBootLoader.git", true),
            new Card("images/game.png", "Neos", "I utilized the power of Java's object-oriented nature as well as the massive ecosystem of gui libraries to make a simple 2D world where a character wanders a pink forest. I thoroughly enjoyed the development and testing of the world model using Java's awt and Swing library.", "https://github.com/AceOfRay/Neos.git", true),
            new Card("images/apps.jpeg", "OrderIt", "OrderIt is an app idea that organizes the steps of cooking amongst one or multiple chefs into a step by step list. Steps and Tasks are scheduled and sorted based on the contraints that exist in your kitchen. It is meant to aid cooks in the stress that is their kitchen. OrderIt is unfinished. ", "https://github.com/AceOfRay/Order_It_Dev.git", true),
        ]
        let resultComponents: React.JSX.Element[] = []

        personalProjectCards.forEach((card) => {
            resultComponents.push(
                <CardComponent card={card} />
            )
        })
        return resultComponents;
    }

    static getOrganizationBasedProjectsComponents() {
        const organizationBasedProjectsCards = [
            new Card("images/cpu.png", "Pipelined CPU", "Developed a 5 stage pipelined CPU at Cal Poly in my Computer Hardware Architecture and Design class using modified components developed in earlier classes. This taught me a lot about the highlevel design of CPU hardware especially in regards to forwarding, control hazards, and data hazards.", "https://github.com/AceOfRay/CPE333.git", true),
            new Card("images/data.png", "Mutation Analysis Data Pipeline", "To investigate and answer target research questions, I developed a multistage Pipeline that builds, analyzes, and reports mutation data about student submitted Java projects. Various commands control the flow of the pipeline and the format of the visualizations", "https://github.com/AceOfRay/CPE333.git", true),
            new Card("images/cpss.jpg", "Airbrakes", "I was a Software Engineer for Cal Poly Space Systems and during my time there I aided on the initial development of the AirBrakes Project. I contributed to the control system to interface with the on-rocket sensors. In doing so I gained valuable experience about interfacing with custom PCB designs through PlatformIO", "https://github.com/cpspacesystems/airbrakes-flight.git", true),
            new Card("images/gdg.png", "Take Home Tasks", "As the lead of Technologies for the Google Developer Group at Cal Poly, it is my responsibility to host workshops that expose participants to Google Technologies. As a form of homework the participants have the option to apply what was learned in the workshop in a Take Home Task.", "https://github.com/GDSC-CalPoly-SLO", true),
            new Card("images/javagame.png", "Java Zombie Game", "During my Object Oriented Programming course at Cal Poly I was tasked to create an Entity Type Hierarchy from a given codebase where each entity was encoded into one massive class. The goal was to implement everything we were learning in class to completely removed the flat level design and have the project still pass all assigned test cases.", "https://github.com/cpe203kazerouni/forest-project-w24-AceOfRay.git", true),
        ]
        let resultComponents: React.JSX.Element[] = []

        organizationBasedProjectsCards.forEach((card) => {
            resultComponents.push(
                <CardComponent card={card} />
            )
        })
        return resultComponents;
    }
}