import Section from "../components/section.tsx"
import Data from "../classes/Data.tsx"

export default function PersonalityPage({}){
  const hobbies = Data.getHobbyComponents();
  const passions = Data.getPassionComponents();
    return (
    <main className="personalityPage">
      <Section className="mainContainer" title="My Hobbies">
        {hobbies}
      </Section>   
      <Section className="mainContainer" title="My Passions">
        {passions}
      </Section>  
    </main>)
}