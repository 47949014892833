import CardComponent from "../components/card.tsx";
import Card from "../classes/Card.ts";
import "../css/projectPage.css";
import Section from "../components/section.tsx";
import Data from "../classes/Data.tsx";

export default function ProjectPage() {

  const cards = new Card("icons/InstaLogo.png", "Demo Title", "lorem ipsum default text bla bla bla kladlijfkljgita; jfa oid kajijfd thishiadhk n thiskdlkghi kdfjfijitks ajwjrkhi skj;", "https://github.com/AceOfRay/TestingAnalysisSURP.git", true)
  const personalProjects = Data.getPersonalProjectsComponents();
  const contributedProjects = Data.getOrganizationBasedProjectsComponents();
  return (
    <main>
      <Section className="mainContainer" title="My Projects">
        {personalProjects}
      </Section>

      <Section className="mainContainer" title="Organization Based Projects">
        {contributedProjects}
      </Section>
      
    </main>
  );
}
