
export default class Card {
    imagePath : string;
    title : string;
    summary : string;
    link : string;
    isProject: boolean;

    constructor (imagePath : string, title : string, summary : string, link: string, isProject=true) {
        this.imagePath = imagePath;
        this.title = title;
        this.summary = summary;
        this.link = link;
        this.isProject = isProject;
    }
}