import "../css/socials.css";

export default function Socials() {
  return (
    <footer className="footer">
      <ul className="list">
        <li>
          <a href="https://www.instagram.com/calls1gn_ray/" target="_blank">
            <img className="insta" src="icons/InstaLogo.png" alt="Instagram Link" />
          </a>
        </li>
        <li>
          <a href="https://github.com/AceOfRay" target="_blank">
            <img className="github" src="icons/githubLogo.jpg" alt="GitHub Link" />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/ramon-valenzuela-ab1511253">
            <img
              className="linkedin"
              src="icons/LinkedInLogo.jpg"
              alt="Linked In Link"
              target="_blank"
            />
          </a>
        </li>
      </ul>
    </footer>
  );
}
